// @file
// Typography
@import '../utils/functions';

$base-font-size: rem(15px);
$line-height-loose: 1.7;
$line-height-normal: 1.45;
$line-height-tight: 1.1;
$vertical-spacing: $base-font-size; // Used as the default spacing below most items such as paragraphs, lists, etc.

// Fonts
$system-font-family: -apple-system,
BlinkMacSystemFont,
'Segoe UI',
Roboto,
Oxygen-Sans,
Ubuntu,
Cantarell,
'Fira Sans',
'Droid Sans',
'Open Sans',
'Helvetica Neue',
sans-serif;
$base-font-family: 'public sans',
sans-serif,
monospace;
$header-font-family: 'source serif 4',
monospace;

// Typographic Scale
$font-size-display: rem(48px);
$font-size-xxxl: rem(36px);
$font-size-xxl: rem(24px);
$font-size-xl: rem(22px);
$font-size-lg: rem(20px);
$font-size-md: $base-font-size;
$font-size-sm: rem(14px);
$font-size-xs: rem(12px);

// Heading Sizes
$font-size-h1: $font-size-xxxl;
$font-size-h2: $font-size-xxl;
$font-size-h3: $font-size-xl;
$font-size-h4: $font-size-lg;
$font-size-h5: $font-size-md;
$font-size-h6: $font-size-md;