@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Research

$spacing: $font-size-h1+$line-height-normal;
$spacing-mobile: $font-size-h2+$line-height-normal;

.p-publications__left {
  display: inline-block;
  margin: $spacing-6 0 0 0;

  @include bp-desktop {
    margin: 0 $spacing-6 0 0;
  }

  @include bp-desktop-lg {
    margin: 0 $spacing-9 0 0;
    min-width: rem(400px);
  }
}