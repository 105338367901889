@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Teaching

$spacing: $font-size-h1+$line-height-normal;
$spacing-mobile: $font-size-h2+$line-height-normal;

.p-teaching__left {
  display: inline-block;
  margin: $spacing-6 0 0 0;

  @include bp-desktop {
    margin: 0 $spacing-6 0 0;
  }

  @include bp-desktop-lg {
    margin: 0 $spacing-9 0 0;
    min-width: rem(400px);
  }
}

.p-teaching__inner {

  @include bp-tablet {
    column-count: 2;
    column-gap: $spacing-9;
  }
}

.p-teaching__btn {
  &.c-button {
    text-decoration: none;

    &::after {
      transform: rotate(45deg);
    }
  }

}