@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Advisors

.c-advisors {
  color: $grey;
  font-size: $font-size-sm;
  margin-bottom: 0;
}

.c-advisors__label {
  font-family: $header-font-family;
  color: $black;
}