@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// About

$spacing: $font-size-h1+$line-height-normal;
$spacing-mobile: $font-size-h2+$line-height-normal;

.p-about {
  .l-split {
    align-items: flex-start;
    overflow: auto;
  }
}

.p-about__left {
  display: inline-block;
  margin: $spacing-6 0 0 0;

  @include bp-desktop {
    margin: 0 $spacing-6 0 0;
  }

  @include bp-desktop-lg {
    margin: 0 $spacing-9 0 0;
    min-width: rem(400px);
  }
}

.p-about__inner {
  @include bp-desktop {
    display: flex;
    gap: $spacing-6;
    justify-content: space-between;
  }

  .p-about__text {
    &:first-child {
      margin-bottom: $spacing-6;
    }

    @include bp-desktop {
      width: calc(50% - ($spacing-6 / 2));

      &:first-child {
        margin-bottom: 0;
        top: calc($spacing-9 * 2);
      }

      &:last-child {
        padding-bottom: $spacing-6;
      }
    }
  }
}


.p-about__btn {
  &.c-button {
    text-decoration: none;

    &::after {
      transform: rotate(45deg);
    }
  }

}