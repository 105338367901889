@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Menu

.c-mobile-menu {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.c-mobile-menu__btn {
  @include button-clear();

  color: $black;
  font-weight: 600;
  position: fixed;
  right: $spacing-2;
  z-index: 4;

  &.c-button--text {
    padding: 0;

    &::before,
    &::after {
      display: none
    }
  }
}

.c-mobile-menu__menu {
  @include list-clean();

  align-items: center;
  background-color: $white;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: -1;
}

.c-mobile-menu__item {
  padding-left: 0 !important;
  margin-bottom: $spacing-3;
}

.c-mobile-menu__link {
  color: $grey;
  font-size: 600;
  text-decoration: none;

  &.is-active {
    color: $blue;
  }
}