@import 'scss/variables';
@import 'scss/utils';

// @file
// Content

.c-content {
  margin-top: $spacing-6;

  @include bp-tablet-lg {
    padding-left: $spacing-3;
  }

  @include bp-desktop {
    margin-left: $spacing-3;
  }

  @include bp-desktop-lg {
    margin-top: $spacing-9;
    margin-left: $spacing-9;
  }
}