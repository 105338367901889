// @file
// Universal styles

#root {
    transition-duration: 0.4s;
    transition-property: background-color, border-color, box-shadow, color, opacity, text-shadow, transform;
    transition-timing-function: ease-in;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

@media print {
    * {
        background-color: transparent !important;
        box-shadow: none !important;
        color: $black !important;
        text-shadow: none !important;
    }

    @page {
        margin: 2cm;
    }
}