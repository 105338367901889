@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Button

.c-button {
  @include button-clear();

  background-color: $blue;
  color: $white;
  display: inline-block;
  font-weight: 600;
  line-height: $line-height-loose;
  width: rem(300px);
  position: relative;
  padding: $spacing-2;
  text-align: left;
  transition: all 0.4s cubic-bezier(0.5, 0.25, 0, 1);
  z-index: 1;

  &:hover {
    background-color: $black;
    color: $white;
  }

  &:disabled {
    opacity: 0.15;
    pointer-events: none;
    background-color: $grey !important;
  }

  @include bp-tablet {
    padding-left: $spacing-3;
    padding-right: $spacing-3;
  }

  &+.c-button {
    margin-left: $spacing-2;
  }
}

.c-button--text {
  background-color: transparent;
  color: $black;
  font-size: $font-size-sm;
  font-weight: 600;
  letter-spacing: 5px;
  padding: 0 $spacing-3 0 0;
  position: relative;
  text-transform: uppercase;
  width: auto;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    transition: all 0.2s ease-in;
  }

  &::before {
    display: block;
    background-color: $grey;
    bottom: 0;
    height: 1px;
    opacity: 0;
    left: 0;
    width: calc(100% - #{$spacing-3});
  }

  &::after {
    @include svg-background(icon-arrow-right);

    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 16px;
    height: 15px;
    right: 0;
    top: calc((100% - 16px) / 2);
    transform: rotate(-45deg);
    width: 20px;
  }

  &:hover {
    background-color: transparent;
    color: $black;

    &::before {
      opacity: 1;
    }

    &::after {
      transform: translate(50%);
    }
  }
}

.c-button--text-small {
  background-color: transparent;
  font-size: $font-size-sm;
  font-weight: 400;
  padding: 0 $spacing-6 0 0;
  width: auto;

  &:hover {
    background-color: transparent;
  }
}