// @file
// Spacing
@import '../utils/functions';

$spacing-1: rem(10px);
$spacing-2: rem(20px);
$spacing-3: rem(30px);
$spacing-4: rem(40px);
$spacing-5: rem(50px);
$spacing-6: rem(60px);
$spacing-7: rem(70px);
$spacing-8: rem(80px);
$spacing-9: rem(90px);
$spacing-10: rem(100px);