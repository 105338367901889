@import "../../../scss/variables";
@import "../../../scss/utils";

// @file
// Home

$spacing: $font-size-h1 + $line-height-normal;
$spacing-mobile: $font-size-h2 + $line-height-normal;

.p-home__headshot {
  padding-top: 0;

  @include bp-desktop {
    padding-top: $spacing-6;
  }

  @include bp-desktop-lg {
    padding-top: 0;
  }
}

.p-home__inner {
  @include bp-desktop-lg {
    max-width: 700px;
  }
}

.p-home__text {
  @include bp-desktop-lg {
    padding-top: #{$spacing};
  }
}
