@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Lines

// .c-lines {}

.c-lines__line {
  background-color: #EFEFEF;
  display: block;
  position: fixed;
  z-index: -1;

  &:first-child,
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7),
  &:nth-child(8) {
    height: 100vh;
    top: 0;
    width: 1px;
  }

  &:first-child {
    left: calc(#{rem(15px)});
  }

  &:nth-child(2) {
    background-color: $blue;
    left: calc(#{rem(15px)} + 120px);
  }

  &:nth-child(3) {
    left: calc(#{rem(15px)} + 180px);
  }

  &:nth-child(4) {
    bottom: 10vh;
    height: 1px;
    left: 0;
    width: 100vw;
  }

  &:nth-child(5) {
    right: rem(15px);
  }

  &:nth-child(6) {
    right: calc(#{rem(15px)} + 60px);
  }

  @include bp-max-desktop {
    &:nth-child(2) {
      display: none;
    }
  }

  @include bp-desktop {
    &:first-child {
      left: calc(#{$spacing-6} + 60px);
    }

    &:nth-child(2) {
      background-color: $blue;
      left: calc(#{$spacing-6} + 120px);
    }

    &:nth-child(3) {
      left: calc(#{$spacing-6} + 180px);
    }

    &:nth-child(4) {
      bottom: 40vh;
    }

    &:nth-child(5) {
      right: 33vh;
    }

    &:nth-child(6) {
      right: calc(33vh + 60px);
    }

    &:nth-child(7) {
      right: rem(15px);
    }

    &:nth-child(8) {
      right: calc(#{rem(15px)} + 60px);
    }
  }
}