// @File
// Colors

// Primary
$black: #212121;
$grey: #666;
$grey-1: #efefef;
$white: #fff;

// Accent
$blue: #005F9E;