@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Header

.l-header {
  align-items: center;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  left: 0;
  padding: $spacing-3 $spacing-2 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 2;

  @include bp-tablet-lg {
    padding-left: $spacing-3;
    padding-right: $spacing-3;
  }

  .c-lines__line {
    position: absolute;
  }
}