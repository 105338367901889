@import 'scss/variables';
@import 'scss/utils';

// @file
// Title

.c-title {
  color: $grey;
  margin-bottom: $spacing-3;
}

.c-title__title {
  font-family: $base-font-family;
  font-weight: 600;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin: 0;
}