@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Split

.l-split {
  align-items: center;
  display: flex;
  min-height: 100vh;
  padding: $spacing-6 $spacing-3;
  width: 100%;

  @include bp-tablet {
    flex-direction: column;
    justify-content: center;
  }

  @include bp-desktop {
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
    padding: calc($spacing-9 * 2) $spacing-9;
  }
}

.l-split__inner {
  padding: 0;

  @include bp-tablet-lg {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @include bp-desktop-lg {
    .p-home & {
      align-items: center;
    }
  }

  .p-home & {
    @include bp-max-tablet-lg {
      max-width: rem(600px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}