@import '../../../scss/variables';
@import '../../../scss/utils';

// @file
// Papers grid

.c-papers-grid {

  &~.c-papers-grid {
    margin-top: $spacing-6;
  }
}

.c-papers-grid__title {
  font-family: $base-font-family;
  font-weight: 600;
  margin-top: $spacing-3;
  text-transform: capitalize;

  &.is-decorated {
    @include bp-desktop {
      position: relative;

      &::before {
        background-color: $blue;
        display: block;
        content: '';
        height: 5px;
        position: absolute;
        top: calc((100% - 5px) / 2);
        left: -90px;
        width: 70px;
      }
    }
  }

}